import React from 'react';
import { /* fade, */ makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

/*
import Badge from '@material-ui/core/Badge';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Button from '@material-ui/core/Button';
*/
import Auth from '@aws-amplify/auth';
// import { ICredentials } from '@aws-amplify/core';
// import CameraIcon from '@material-ui/icons/PhotoCamera';
import Drawer from '../Drawer';
import { DesktopMenu, DesktopMenuIcon } from './DesktopMenu';
import { MobileMenu, MobileMenuIcon } from './MobileMenu';
import { AuthContext } from 'utils/auth/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    title: {
      display: 'block',
      // display: 'none',
      // [theme.breakpoints.up('sm')]: {
      //     display: 'block',
      // },
    },
    /*
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing(7),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 7),
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 200,
            },
        },
        */
    session: {
      paddingTop: theme.spacing(1.5),
      marginLeft: theme.spacing(2),
    },
  }),
);

const MyToolbar: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const auth = React.useContext(AuthContext);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    Auth.signOut()
      .then(data => {
        console.log(data);
        setAnchorEl(null);
        auth[1]({
          type: 'login',
          state: { authenticated: null, authStatus: 'completed' },
        });
        history.push('/login');
      })
      .catch(err => console.log('Auth.signOut', err));
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {auth[0].authenticated ? <Drawer /> : null}
          <Typography className={classes.title} variant="h6" noWrap>
            Fotopia
          </Typography>
          <div className={classes.grow} />
          <DesktopMenuIcon {...{ menuId, handleProfileMenuOpen }} />
          <div className={classes.session}>{auth[0].authenticated?.email}</div>
          <MobileMenuIcon {...{ mobileMenuId, handleMobileMenuOpen }} />
        </Toolbar>
      </AppBar>
      <MobileMenu
        {...{
          mobileMenuId,
          mobileMoreAnchorEl,
          isMobileMenuOpen,
          handleMobileMenuClose,
          handleProfileMenuOpen,
        }}
      />
      <DesktopMenu
        menuId={menuId}
        anchorEl={anchorEl}
        isMenuOpen={isMenuOpen}
        handleMenuClose={handleMenuClose}
        handleLogout={handleLogout}
      />
    </>
  );
};

export default React.memo(MyToolbar);
