import React, { useReducer, Dispatch } from 'react';
import Auth from '@aws-amplify/auth';

export type LoggedUser = { email: string, jwtToken: string } | null;
export type AuthStatus = 'pending' | 'completed';
export type AuthState = { authStatus: AuthStatus; authenticated: LoggedUser };

type Action =
  | { type: 'status'; status: AuthStatus }
  | { type: 'login'; state: AuthState }
  | { type: 'authenticated'; authenticated: LoggedUser };

// export type God = {
//   authStatus: AuthStatus;
//   setAuthStatus: (status: AuthStatus) => void;
//   authenticated: LoggedUser;
//   setAuthenticated: (user: LoggedUser) => void;
// };

type Store = [AuthState, Dispatch<Action>];
const initialState: AuthState = {
  authStatus: 'pending',
  authenticated: null,
};

export function reducer(state: AuthState, action: Action) {
  switch (action.type) {
    case 'status':
      return { ...state, authStatus: action.status };
    case 'authenticated':
      return { ...state, authenticated: action.authenticated };
    case 'login':
      return { ...state, ...action.state };
    default:
      throw new Error();
  }
}

export function useAuthReducer(): Store {
  return useReducer(reducer, initialState);
}

export const AuthContext = React.createContext<Store>([initialState, (_: Action) => {}]);

export const useAuthSessionEffect = ([state, dispatch]: Store) => {
  React.useEffect(() => {
    console.log('useAuthSessionEffect 0', state.authStatus);
    state.authStatus === 'completed' ||
      Auth.currentSession()
        .then(userSession => {
          console.log('useAuthSessionEffect 1', state.authStatus);
          const jwtToken = userSession.getIdToken().getJwtToken();
          const { email } = userSession.getIdToken().payload;
          dispatch({
            type: 'login',
            state: { authenticated: { email, jwtToken }, authStatus: 'completed' },
          });
        })
        .catch(err => {
          dispatch({
            type: 'login',
            state: { authenticated: null, authStatus: 'completed' },
          });
          console.warn('useAuthSessionEffect 2', state.authStatus, err);
        });
  });
};
