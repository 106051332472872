import React, { Suspense, lazy } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Loader from 'components/Loader';
import Toolbar from 'components/Toolbar';
import Main from 'components/Main';
//import Footer from 'components/Footer';
import { AuthRoute } from './AuthRoute';
import { AuthContext, useAuthSessionEffect } from 'utils/auth/hooks';

// import { useAuth } from './hooks';

const Landing = lazy(() => import(/* webpackChunkName: "landing" */ 'pages/Landing'));
const Album = lazy(() => import(/* webpackChunkName: "album" */ 'pages/Album'));
const AlbumNew = lazy(() => import(/* webpackChunkName: "AlbumNew" */ 'pages/Album/AlbumNew'));
const AlbumDetail = lazy(() => import(/* webpackChunkName: "AlbumDetail" */ 'pages/Album/AlbumDetail'));
const AlbumList = lazy(() => import(/* webpackChunkName: "AlbumList" */ 'pages/Album/AlbumList'));

const PhotoNew = lazy(() => import(/* webpackChunkName: "PhotoNew" */ 'pages/Album/Photo/PhotoNew'));

const Login = lazy(() => import(/* webpackChunkName: "login" */ 'pages/Login'));
const Check = lazy(() => import(/* webpackChunkName: "check" */ 'pages/Check'));

const Router = () => {
  const auth = React.useContext(AuthContext);
  useAuthSessionEffect(auth);

  return (
    <BrowserRouter>
      {auth[0].authenticated ? <Toolbar /> : null}
      <Suspense fallback={<Loader />}>
        <Switch>
          <Main>
            <AuthRoute exact path="/" render={() => <Landing />} />
            <AuthRoute exact path="/album" render={() => <Album />} />
            <AuthRoute path="/album/new" render={() => <AlbumNew />} />
            <AuthRoute path="/album/:barcode/edit" render={() => <AlbumDetail />} />
            <AuthRoute path="/album/:barcode/list" render={() => <AlbumList />} />
            <AuthRoute path="/album/:barcode/photo/new" render={() => <PhotoNew />} />
            <Route path="/login" render={() => <Login />} />
            <AuthRoute path="/check" render={() => <Check />} />
          </Main>
        </Switch>
      </Suspense>
      {/* <Footer /> */}
    </BrowserRouter>
  );
};

export default Router;
