import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
  }),
);

const Main: React.FC = ({ children }: { children?: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <main>
      <div className={classes.heroContent}>{children}</div>
    </main>
  );
};

export default Main;
