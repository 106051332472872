import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AuthContext } from 'utils/auth/hooks';
import Loader from 'components/Loader';

export const AuthRoute = (props: RouteProps /* & { auth: AuthState} */) => {
  const { render: hocRender, ...rest } = props;
  const [authState] = React.useContext(AuthContext);

  console.log('AuthRoute - 0', props.path);
  // type Pig = RouteComponentProps(from react-router-dom)<any, StaticContext (from react-router), any>;

  if (!hocRender) {
    throw new Error('`render` prop method is mandatory for AuthRoute');
  }
  return (
    <Route
      {...rest}
      render={({ history, location, match }) => {
        console.log('AuthRoute - 3', authState.authStatus, props.path);

        if (authState.authStatus === 'pending') {
          return <Loader />;
        }

        return authState.authenticated ? (
          hocRender({ history, location, match })
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );

  // return isLoggedRef()
  //     ? <Route {...rest } render={({ location }) => render({} as Pig) } />
  //     : (
  //         <Redirect
  //             to={{
  //                 pathname: "/login",
  //                 // state: { from: location }
  //             }}
  //         />
  //     )
};

// export const AuthRouteOrig = ({ render, ...rest }: { render: () => React.ReactElement, rest: any }) =>
// <Route {...rest} render={({ location }) =>
//     Auth.currentSession() ? render() : (
//         <Redirect
//             to={{
//                 pathname: "/login",
//                 state: { from: location }
//             }}
//         />
//     )
// }
// />
