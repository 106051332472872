import React, { useCallback, useMemo, memo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export const MenuDrawer = () => {
  // useCurrentRoute returns the lastest loaded Route object
  // const { data: routes } = useCurrentRoute();
  // const items = Object.keys(routes);

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  type DrawerSide = 'top' | 'left' | 'bottom' | 'right';
  const toggleDrawer = useCallback(
    (side: DrawerSide, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [side]: open });
    },
    [state],
  );

  const togglerDrawerLeftOn = useMemo(() => toggleDrawer('left', true), [toggleDrawer]);
  const togglerDrawerLeftOff = useMemo(() => toggleDrawer('left', false), [toggleDrawer]);

  const sideList = useCallback(
    (_side: DrawerSide) => (
      <div className={classes.list} role="presentation" onClick={togglerDrawerLeftOff} onKeyDown={togglerDrawerLeftOff}>
        {/* 
            <List>
                {items.map(id => (
                    <ListItem button key={id}>
                        <Link href={`/product/${id}`} prefetch={true}>
                            <ListItemText primary={routes[id].title} />
                        </Link>
                    </ListItem>
                ))}
            </List>
            <Divider />
            */}

        <List>
          <ListItem button key="home" component={Link} to="/">
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem key="album" component={Link} to={'/album'}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Album" />
          </ListItem>
          <ListItem key="login" component={Link} to={'/login'}>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Login" />
          </ListItem>
          <ListItem key="check" component={Link} to={'/check'}>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Check" />
          </ListItem>
        </List>
      </div>
    ),
    [classes.list, togglerDrawerLeftOff],
  );

  const sideListLeft = useMemo(() => sideList('left'), [sideList]);

  return (
    <>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="open drawer"
        onClick={togglerDrawerLeftOn}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        transitionDuration={300}
        open={state.left}
        onClose={togglerDrawerLeftOff}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
          closeAfterTransition: true,
        }}
      >
        {sideListLeft}
      </Drawer>
    </>
  );
};

export default memo(MenuDrawer);
