import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

// import CameraIcon from '@material-ui/icons/PhotoCamera';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useAuthReducer, AuthContext } from 'utils/auth/hooks';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    /*
        icon: {
            marginRight: theme.spacing(2),
        },
        cardGrid: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
        },
        card: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        cardMedia: {
            paddingTop: '56.25%', // 16:9
        },
        cardContent: {
            flexGrow: 1,
        },
        */
  }),
);

const Layout: React.FC = ({ children }: { children?: React.ReactNode }) => {
  const classes = useStyles();
  const reducer = useAuthReducer();
  // const [ isAuth, setIsAuth] = React.useState<LoggedUser>(null);
  // const [ authStatus, setAuthStatus] = React.useState<AuthStatus>('pending');

  return (
    <div className={classes.grow}>
      <CssBaseline />
      <AuthContext.Provider value={reducer}>{children}</AuthContext.Provider>
    </div>
  );
};

export default Layout;
