export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'attachmentsbucketdev',
  },
  apiGateway: {
    REGION: 'YOUR_API_GATEWAY_REGION',
    URL: 'YOUR_API_GATEWAY_URL',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_8VH0JSaXR',
    APP_CLIENT_ID: '37n9lsusob2k7mf88c4d69h0ku',
    IDENTITY_POOL_ID: 'us-east-1:75263ba0-0d66-44b1-b7e5-b6c027091409',
  },
};
