import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';

import Router from 'components/Router';
import Layout from 'components/Layout';
import aws from 'utils/aws';

aws.init();
// import api from './api'

// import './index.css';
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  // <React.StrictMode>
  <Layout>
    <Router />
  </Layout>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
