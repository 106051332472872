import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
// import API from '@aws-amplify/api';
import Storage from '@aws-amplify/storage';

import config from '../config';

const AWSConfig = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    // identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  // API: {
  // 	endpoints: [
  // 		{
  // 			name: 'testApiCall',
  // 			endpoint: config.apiGateway.URL,
  // 			region: config.apiGateway.REGION
  // 		}
  // 	]
  // }
};

export default {
  init() {
    Amplify.configure(AWSConfig);
    Auth.configure(AWSConfig);
    Storage.configure(AWSConfig);
    // API.configure(AWSConfig);
  },
};
